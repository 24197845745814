<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in dtlFlds"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";

export default {
  mixins: [FormMixin, ShowMixin, AddressBookCorrespondenceMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "broker",
      allRepositories: ["broker", "correspondence", "address_book"],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        brokers: {},
      },
      status_salesman: 0,
      detailFields: {
        status_salesman_0: {
          scheda_contatto: {
            header: "Scheda Produttore",
            edit: false,
            repository: "broker",
            fields: [
              {
                label: this.getDictionary("attribute_BUID_value", "broker"),
                value: "attributables.BUID",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "broker"),
                value: "attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "broker"),
                value: "attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_CNAM_value", "broker"),
                value: "attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "broker"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "broker"),
                value: "attributables.CREG",
              },

              {
                label: this.getDictionary("attribute_WHT_value", "broker"),
                value: "attributables.WHT",
              },
              {
                label: this.getDictionary("attribute_FCPR_value", "broker"),
                value: "attributables.FCPR",
              },
            ],
          },
        },
        status_salesman_1: {
          scheda_contatto: {
            header: "Scheda Produttore",
            edit: false,
            repository: "broker",
            fields: [
              {
                label: this.getDictionary("attribute_BUID_value", "broker"),
                value: "attributables.BUID",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "broker"),
                value: "attributables.CREG",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "broker"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_CNAM_value", "broker"),
                value: "attributables.CNAM",
              },
            ],
          },
        },
        scheda_recapito: {
          header: "Recapiti",
          edit: false,
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 0,
            },
          ],
        },
        scheda_indirizzo: {
          header: "Indirizzo",
          edit: false,
          repository: "correspondence",
          fields: [
            {
              label: this.getDictionary(
                "attribute_NAME2_value",
                "correspondence"
              ),
              value: "attributables.NAME2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attributables.ADDR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY2_value",
                "correspondence"
              ),
              value: "attributables.CITY2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary("province"),
              value: "attributables.CITY",
              relation: "correspondences",
              virtual: "PROVINCE",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ZIPC_value",
                "correspondence"
              ),
              value: "attributables.ZIPC",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              virtual: "NATION",
              relation: "correspondences",
              status: 0,
            },
            {
              label: `${this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              )} estera`,
              value: "attributables.NOR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
          ],
        },
      },
    };
  },
  methods: {
    fetchForm() {
      let queryString = `relations/byAddressBook/byCorrespondence`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.status_cooperator = data.attributables.NAME ? 0 : 1;
          if (!(data.correspondences && data.correspondences.length)) {
            this.$delete(this.detailFields, "scheda_indirizzo");
          }
          if (!(data.address_books && data.address_books.length)) {
            this.$delete(this.detailFields, "scheda_recapito");
          }
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
  },
  computed: {
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    dtlFlds() {
      // mergia i campi di status 0 o 1 con quelli in comune
      let { status_salesman_0, status_salesman_1, ...rest } = this.detailFields;

      let c = this.status_salesman ? status_salesman_1 : status_salesman_0;
      let r = { ...c, ...rest };
      return r;
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetchForm();
  },
};
</script>
